<template>
  <div>
    <dialog-edit2 :value="value" @input="$emit('input')">
      <template v-slot:title>
        <small v-if="id"> ({{ id }})</small>
        {{ id ? m.title : "Новый документ '" + m.title + "'" }}
      </template>

      <a-loader v-if="!modelReady && data.type" />
      <a-form-modelCols ref="model" :key="type" v-model="data" :model="model" :errors="errors" :config="{ dense: true }" @validate="validate($event)" />
      <template v-slot:actions>
        <v-spacer></v-spacer>
        <a-btn-save @click="submit()"></a-btn-save>

        <v-btn @click="$emit('input')">Закрыть</v-btn>
      </template>
    </dialog-edit2>
  </div>
</template>

<script>
import { getForm, submitForm, removeEl, getAccess } from "@/components/mixings";

export default {
  components: {
    //  usersDialog: () => import("./usersDialog2"),
  },
  mixins: [getForm, submitForm, removeEl, getAccess],
  props: {
    value: Boolean,
    id: Number,
    initType: Number,
    api: String,
    m: Object,
    hasData: { type: Boolean, default: false },
  },
  data() {
    return {
      removeDialogShow: false,
      idEdit: 0,
      suEditShow: false,
      modelDoc: null,
      modelReady: false,
      type: null,
      loaded: false,
    };
  },
  computed: {
    model1: {
      get() {
        return this.calcModel();
      },
    },

    model() {
      let model = JSON.parse(JSON.stringify(this.m.form));
      model.forEach((el) => {
        if (this.hasData) {
          el.readonly = true;
        }
        if (!this.id && el?.hiddenCreate) {
          el.hidden = true;
        }
        if (el?.sourceList) {
          //если список вариантов находится во внешнем массиве, присоединяю его
          el.options = this.m?.[el.sourceList]
            ? this.m[el.sourceList].filter((list) => {
                return !list?.hidden;
              })
            : [];
        }
      });
      return model;
    },
    editObject() {
      return false;
      return this.getAccess("object.edit", {
        id: this.object_.id,
        access: this.object_?.object_access || [],
      });
    },
  },
  created() {
    this.loaded = false;
    this.type = this.initType || null;
    this.updateData(this.id);
    if (!this.id) {
      ///  this.type = 1;
      //  this.data.status = 0;
    }
  },
  watch: {},
  methods: {
    afterRemove(data) {
      this.removeDialogShow = false;
      this.$emit("input");
    },
    afterSave(data, status) {
      if (status) this.$emit("input");
      let id = data.id;
      if (!this.id) this.$router.push({ name: this.m.routeName, params: { id } });
    },
    afterFetchData(r) {
      this.loaded = true;
      // this.initType = null;

      //  this.calcModel(1);
    },
    calcModel(t) {
      let type = t || this.initType || 1;
      let model = JSON.parse(JSON.stringify(this.m.form)) || [];
      model.forEach((el) => {
        if (this.hasData && el.name !== "seller_id") {
          el.readonly = true;
        }
        if (this.id && el.name == "date_doc") {
          el.hidden = !true;
        }
        if (el?.sourceList) {
          //если список вариантов находится во внешнем массиве, присоединяю его
          el.options = this.m?.[el.sourceList]
            ? this.m[el.sourceList].filter((list) => {
                return !list?.hidden;
              })
            : [];
        }
        if (el.name == "operation_type") {
          el.options = this.$root.dirs.operationTable.filter((el) => {
            return this.m.operations[type].includes(el.value);
          });
        }
      });
      if (t) {
        this.modelDoc = model;
        this.modelReady = true;
      }
      return model;
    },
  },
};
</script>
